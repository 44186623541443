<template>
  <v-container>
    <!-- 削除確認ダイアログ -->
    <v-dialog v-model="confirmDeleteDialog" max-width="500">
      <v-card>
        <v-card-title class="text-h5">{{
          $t('message.confirmation')
        }}</v-card-title>
        <v-card-text>{{ $t('message.delete_confirmation_text') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="warning" @click="confirmDelete">{{
            $t('message.delete')
          }}</v-btn>
          <v-btn text @click="confirmDeleteDialog = false">{{
            $t('message.cancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 削除成功メッセージ -->
    <v-snackbar v-model="snackbar" :timeout="3000" color="success">
      {{ $t('message.deleteSuccessSnackbar') }}
    </v-snackbar>

    <h2 class="d-flex justify-center align-center" style="text-align: center">
      {{ $t('message.glamour_recipe_title') }}
    </h2>

    <EstoreCard />

    <!-- 検索欄 -->
    <div class="input-area">
      <v-toolbar dark color="gray darken-3" class="mb-1">
        <v-text-field
          v-model="searchQuery"
          :label="$t('message.search')"
          single-line
          flat
          solo-inverted
          hide-details
          clearable
          prepend-inner-icon="mdi-magnify"
        />
      </v-toolbar>
    </div>

    <!-- ボタンとスイッチを横並びに配置 -->
    <div class="d-flex justify-center align-center mb-4 flex-row">
      <v-spacer />
      <!-- ミラプリレシピを作成するボタン (認証されていなくても表示) -->
      <v-chip
        color="primary"
        class="mr-4"
        label
        @click="goToGlamourRecipeMaker"
      >
        <!-- アイコン追加 -->
        <v-icon left>mdi-plus-circle-outline</v-icon>
        {{ $t('message.create_glamour_recipe') }}
      </v-chip>

      <!-- 自分の投稿のみ表示するか全ての投稿を表示するかを切り替えるチップ (認証されている場合のみ表示) -->
      <v-chip
        v-if="$auth.isAuthenticated"
        :color="showMyPostsOnly ? 'green lighten-1' : 'grey lighten-1'"
        class="mr-4"
        label
        @click="toggleMyPostsOnly"
      >
        <!-- アイコンとテキストを状態に応じて変更 -->
        <v-icon left>
          {{ showMyPostsOnly ? 'mdi-check-circle-outline' : 'mdi-filter' }}
        </v-icon>
        {{
          showMyPostsOnly
            ? $t('message.show_only_my_posts')
            : $t('message.show_all_posts')
        }}
      </v-chip>
    </div>

    <div class="d-flex justify-center align-center">
      <div
        v-masonry
        transition-duration="0s"
        item-selector=".item"
        class="masonryWrap"
        :origin-top="true"
        :horizontal-order="false"
      >
        <div
          v-for="(item, index) in events"
          :key="index"
          class="image-container"
        >
          <div
            v-for="(i, ind) in item.image_urls"
            :key="ind"
            v-masonry-tile
            class="item col-6 col-md-4 col-lg-2"
          >
            <div class="image-wrapper">
              <img :src="i" class="item-image" @click="openDialog(i, item)" />
              <!-- タイトル表示部分 -->
              <div class="overlay">
                <div class="text">{{ item.glamour_title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-intersect="onIntersect" />

    <!-- ダイアログ表示 -->
    <v-dialog
      :key="dialogKey"
      v-model="dialog"
      :width="isMobile ? '90vw' : '50vw'"
      height="auto"
    >
      <v-card color="grey lighten-4" flat class="elevation-2">
        <!-- 画像部分 -->
        <v-img
          :lazy-src="imgSelectedEvent"
          :src="modifiedImageSrc"
          class="dialog-image"
          contain
          :max-height="isMobile ? '60vh' : '70vh'"
        >
          <template v-slot:placeholder>
            <div class="fill-height ma-0 d-flex align-center justify-center">
              <v-progress-circular indeterminate color="grey lighten-5" />
            </div>
          </template>
        </v-img>
        <v-divider class="mx-4"></v-divider>

        <!-- カードタイトル -->
        <v-card-title class="text-h4 font-weight-bold">{{
          dialogItem.glamour_title || 'No Title'
        }}</v-card-title>

        <!-- テキスト説明 -->
        <v-card-text class="text-h5 font-weight-bold">{{
          dialogItem.glamour_text || 'No Description'
        }}</v-card-text>

        <!-- ユーザー名表示 -->
        <v-card-subtitle class="text-h5 font-weight-bold">{{
          dialogItem.user_name
        }}</v-card-subtitle>

        <!-- 自分の投稿のみ削除ボタン表示 -->
        <v-card-actions class="justify-end pa-3">
          <v-spacer />

          <v-btn
            v-if="$auth.isAuthenticated && dialogItem.user_id === currentUser"
            color="warning"
            @click="openDeleteDialog(dialogItem)"
          >
            {{ $t('message.delete') }}
          </v-btn>

          <v-btn text color="secondary" @click="dialog = false">{{
            $t('message.close')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'GlamourRecipe',
    components: {},
    metaInfo: {
      title: 'GlamourRecipe',
      titleTemplate: '%s | FFXIV Housing Eden',
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { property: 'og:type', content: 'website' },
        { name: 'twitter:card', content: 'summary_large_image' },
        { property: 'og:url', content: 'https://ff14eden.work' },
        { property: 'og:title', content: 'FFXIV Housing Eden' },
        {
          name: 'description',
          content:
            'FFXIV Housing EdenはFFXIV(ファイナルファンタジー14)でのハウジング情報を配信するサイトです。',
        },
        {
          property: 'og:image',
          content: 'https://ff14eden.work/twitter_card.jpg?3',
        },
      ],
    },
    data: function () {
      return {
        confirmDeleteDialog: false, // 削除確認ダイアログの表示フラグ
        deleteTargetItem: null, // 削除対象の投稿
        snackbar: false, // 削除成功のメッセージ表示フラグ
        isMobile: false, // 画面の大きさに応じてスマホかどうかを判断する
        showMyPostsOnly: false, // スイッチで切り替えるフラグ
        currentUser: '', // ログインしているユーザーID
        dialog: false,
        dialogKey: 0,
        tweetURL: '',
        imgSelectedEvent: '',
        allEvents: [],
        events: [],
        currentPage: 1,
        perPage: 10,
        isLoading: false,
        dialogItem: '',
        user: true,
        discordButton: false,
        searchQuery: '',
      };
    },
    computed: {
      modifiedImageSrc() {
        let url = '';
        if (this.imgSelectedEvent !== '') {
          url = new URL(this.imgSelectedEvent);
          url.searchParams.delete('name');
        }
        return url.toString();
      },
    },
    watch: {
      '$vuetify.breakpoint.smAndDown'(newVal) {
        this.isMobile = newVal; // 画面サイズの変更に応じてスマホかどうかを更新
        console.log('this.isMobile:' + this.isMobile);
      },

      // searchQueryが変更されたときに実行される関数
      searchQuery: function (newQuery) {
        this.events = []; // 2. eventのクリア
        this.currentPage = 1; // ページをリセット
        this.loadMoreEvents(); // 3. loadMoreEventsの実行
      },
      showMyPostsOnly() {
        this.fetchUserMetadata();
        this.events = [];
        this.currentPage = 1;
        this.loadMoreEvents();
      },
    },
    mounted: async function () {
      // Vuetifyのbreakpointを使用
      this.isMobile = this.$vuetify.breakpoint.smAndDown;
      console.log('this.isMobile:' + this.isMobile);

      // ミラプリレシピデータを取得
      await this.fetchEvents();
    },
    methods: {
      openDeleteDialog(item) {
        this.deleteTargetItem = item; // 削除対象をセット
        this.confirmDeleteDialog = true; // 確認ダイアログを開く
      },
      async confirmDelete() {
        try {
          // 削除処理
          await this.deletePost(this.deleteTargetItem);
          this.snackbar = true; // 成功メッセージを表示
        } catch (error) {
          console.error('削除に失敗しました:', error);
        } finally {
          this.confirmDeleteDialog = false; // 確認ダイアログを閉じる
        }
      },
      toggleMyPostsOnly() {
        this.showMyPostsOnly = !this.showMyPostsOnly;
      },
      async fetchUserMetadata() {
        try {
          await this.$nextTick(); // DOM更新後まで待つ

          // Auth0のインスタンスがあり、ユーザーが認証されているか確認
          if (this.$auth && this.$auth.isAuthenticated) {
            const decodedToken = await this.$auth.getIdTokenClaims();

            // トークンが取得できたか確認
            if (decodedToken) {
              // カスタムクレームからscreen_nameを取得
              const userMetadata =
                decodedToken['https://housingeden.user.metadata/user_metadata'];

              if (userMetadata) {
                // screen_nameをcurrentUserに設定
                this.currentUser = userMetadata.screen_name;
                console.log('Twitter ID set to:', this.currentUser);
              } else {
                console.error('No user metadata found in token');
              }
            } else {
              console.error('Failed to decode token');
            }
          }
        } catch (error) {
          console.error('Error during authentication check:', error);
        }
      },
      async deletePost(item) {
        try {
          // ユーザー情報を取得
          this.userInfo = this.$auth.user || {}; // undefined対策でデフォルト値に空オブジェクト
          const username = this.userInfo.name || 'Unknown user';

          // 装備情報をJSON形式で構築
          const equipmentDetails =
            item.equipments?.map((equipment) => ({
              name: equipment.name,
              imageUrl: equipment.image_url,
              colors: equipment.colors || [],
              colorNames: equipment.colorNames || [],
            })) || [];

          // Discordに送信するデータ形式に合わせる
          const mergedData = {
            messageType: 'deleteGlamourRecipe', // 削除イベントとして識別
            glamour_id: item.glamour_id,
            title: item.glamour_title || 'No title', // タイトルが空の場合はデフォルトのタイトル
            remarks: '投稿が削除されました', // 備考として削除通知
            user: {
              name: username,
              nickname: this.userInfo.nickname || 'Unknown nickname',
              picture: this.userInfo.picture || '',
              twitterId: this.twitterId || '',
              userMetadata: this.userMetadata || '',
            },
            equipment: equipmentDetails,
          };

          // Discord Webhook URL
          const webhookURL =
            'https://discord.com/api/webhooks/1283787301016571964/YtN_GbuyJKiiHM6oSMzqHHc0yLS50-d1UAqqd3JC3oxxy1YZT-g0Iu9YaX5xw8Cgv_m_';

          // Discordに削除通知を送信
          await axios.post(webhookURL, {
            content: JSON.stringify(mergedData, null, 2),
          });
          console.log('削除データがDiscordに送信されました');

          // ローカルのイベントリストから削除
          this.events = this.events.filter(
            (event) => event.glamour_id !== item.glamour_id
          );
          this.allEvents = this.allEvents.filter(
            (event) => event.glamour_id !== item.glamour_id
          );
          this.dialog = false; // ダイアログを閉じる
        } catch (error) {
          console.error('削除に失敗しました:', error);
        }
      },
      goToGlamourRecipeMaker() {
        // ミラプリレシピメーカーのページに遷移
        this.$router.push({ path: '../glamour-recipe-maker/' });
      },
      openDialog(imageUrl, item) {
        this.fetchUserMetadata();
        this.imgSelectedEvent = imageUrl;
        this.tweetURL = item.tweet_url;
        this.dialog = true;
        this.dialogItem = item;
        this.dialogKey++; // キーの値をインクリメント
        // console.log('ダイアログitem')
        console.log(item);
      },
      async fetchEvents() {
        try {
          if (this.allEvents.length === 0) {
            const response = await axios.get(
              `../../../glamour/all_users_glamour.json?timestamp=${new Date().getTime()}`
            );

            this.allEvents = response.data
              .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
              .map((event) => {
                // event.image_urlsがundefinedでないことを確認
                if (event.image_urls && Array.isArray(event.image_urls)) {
                  event.image_urls = [
                    ...new Set(event.image_urls.filter(this.isValidHttpUrl)),
                  ];
                } else {
                  console.warn('event.image_urlsが無効です:', event.image_urls);
                  event.image_urls = []; // デフォルト値として空の配列
                }
                return event;
              });

            console.log(this.allEvents);
            this.loadMoreEvents(); // イベントをさらにロード
          }
        } catch (error) {
          console.error('fetchEventsでエラーが発生しました: ', error);
        }
      },

      isValidHttpUrl(url) {
        try {
          const validUrl = new URL(url);
          return (
            validUrl.protocol === 'http:' || validUrl.protocol === 'https:'
          );
        } catch (error) {
          console.warn('無効なURLが検出されました: ', url);
          return false;
        }
      },
      loadMoreEvents() {
        const existingImageUrlsSet = new Set();
        const baseOfUrl = (url) => new URL(url).origin + new URL(url).pathname;
        this.events.forEach((event) => {
          event.image_urls.forEach((url) =>
            existingImageUrlsSet.add(baseOfUrl(url))
          );
        });
        const start = (this.currentPage - 1) * this.perPage;
        const end = this.currentPage * this.perPage;

        let filteredEvents = this.allEvents;

        // 自分の投稿のみ表示するか全て表示するかの切り替え
        console.log('this.currentUser:' + this.currentUser);
        if (this.showMyPostsOnly) {
          filteredEvents = filteredEvents.filter(
            (event) => event.user_id === this.currentUser
          );
        }

        // searchQueryが空でない場合のみフィルタリングを実行
        const searchQuery = this.searchQuery || '';
        if (searchQuery.trim() !== '') {
          filteredEvents = this.allEvents.filter((event) => {
            console.log(event);

            // Equipmentsの中に検索クエリが含まれるかチェックする関数
            const matchesEquipments = event.equipments?.some((equipment) => {
              // equipmentのnameとcolorNamesを検索
              const matchesEquipmentName = equipment?.name
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase());
              const matchesEquipmentColors = equipment?.colorNames?.some(
                (colorName) =>
                  colorName?.toLowerCase().includes(searchQuery.toLowerCase())
              );
              return matchesEquipmentName || matchesEquipmentColors;
            });

            // textまたはuser_nameプロパティ、またはequipments内の項目が検索クエリを含むかチェック
            return (
              event.glamour_title
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              event.glamour_text
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              event.user_name
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              matchesEquipments
            );
          });
        }

        if (start < filteredEvents.length) {
          const newEvents = JSON.parse(
            JSON.stringify(filteredEvents.slice(start, end))
          );
          newEvents.forEach((event) => {
            event.image_urls = event.image_urls
              .map((url) => {
                const newUrl = new URL(url);
                newUrl.searchParams.set('format', 'jpg');
                newUrl.searchParams.set('name', '900x900');
                return newUrl.toString();
              })
              .filter((url) => !existingImageUrlsSet.has(baseOfUrl(url)));
          });
          this.events = this.events.concat(newEvents);
          this.currentPage++;
        }
      },

      onIntersect(entries) {
        if (
          entries[0].isIntersecting &&
          !this.isLoading &&
          this.events.length < this.allEvents.length
        ) {
          this.isLoading = true;
          this.loadMoreEvents();
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style>
  .masonryWrap {
    box-align: center;
    width: 100%;
  }

  .item {
    padding: 5px;
  }

  .image-wrapper {
    position: relative;
    overflow: hidden;
  }

  .item-image {
    width: 100%;
    height: auto;
    margin: 0;
    display: block;
    transition: transform 0.3s ease, filter 0.3s ease; /* 拡大縮小アニメーション */
  }

  .item-image:hover {
    transform: scale(1.1); /* 拡大する */
    filter: brightness(80%); /* 明度を少し暗くする */
  }

  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7); /* 半透明の黒背景 */
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: 0.5s ease;
  }

  .image-wrapper:hover .overlay {
    height: 25px; /* マウスオーバー時に表示する高さ */
  }

  .text {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }

  .flex-center {
    display: flex;
    justify-content: center; /* 横方向の中央寄せ */
    align-items: center; /* 縦方向の中央寄せ */
    flex-direction: column; /* 子要素を縦方向に並べる */
    height: 100%;
  }

  .dialog-image {
    max-width: 100%;
    max-height: 70vh;
    object-fit: contain;
  }
  .v-card {
    border-radius: 12px; /* カードの角を丸める */
  }
</style>
